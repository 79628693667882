export default {
  s01: "040000179002008054006005008080070910050090030019060040300400700570100200928000060",
  s02: "206000049037009000100700006000580900705000804009062000900004001000300490410000208",
  s03: "050200000300005080960078200000030020708000103040080000001640032070500001000009050",
  s04: "050090000004800009000107280560000137000000000173000042021508000600003800000010060",
  s05: "105000370000000200097300010000053102300801004201470000070008640008000000012000807",
  s06: "008060900000203678706051004973048100620039050001700000580900306000000000040005721",
  hiddenCandidates:
    "000000000904607000076804100309701080708000301051308702007502610005403208000000000",
  hiddenCandidates3:
    "000001030231090000065003100678924300103050006000136700009360570006019843300000000",
  nyt5_18: "008003004090000027010040030000000060805204003200070000000930015000008000000107006",
  xWing: "100000569492056108056109240009640801064010000218035604040500016905061402621000005",
  yWing: "900240000050690231020050090090700320002935607070002900069020073510079062207086009",
  pointingPairs:
    "017903600000080000900000507072010430000402070064370250701000065000030000005601720",
  hiddenCandidates2:
    "720408030080000047401076802810739000000851000000264080209680413340000008168943275",
  underUsed:
    "800006305040000070000000000010038704000104000300070290000003000020000040506800002",
  hard1: "002090300805000000100000000090060040000000058000000001070000200300500000000100000",
};
